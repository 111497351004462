<template>
    <main-modal @closeModal="$emit('closeLightBox')" :key="desktopViewport" style="z-index: 10;">
        <h5 class="mb-4 title-20">{{$t('product.image_gallery')}} ({{images.length}})</h5>
        <div class="desktop-gallery d-flex justify-content-between" :class="{'flex-column-reverse': !desktopViewport}">
            <div class="position-relative" :class="{'me-4': desktopViewport}">
                <swiper ref="swiper" :options="swiperConfig" @fromEdge="gradient[desktopViewport ? 'desktop':'mobile'] = false" :class="{'swiper-gallery': !desktopViewport}">
                    <swiper-slide v-for="{picture_url} in images" :key="picture_url" @mouseenter.native="()=> {if(desktopViewport){img = picture_url}}" @click.native="img = picture_url">
                        <image-product :source="picture_url" class="img-product-details loaded" :class="{'active': picture_url === img}"/>
                    </swiper-slide>
                </swiper>
                <transition name="fade">
                    <template v-if="desktopViewport">
                        <div v-show="gradient.desktop && images.length > 5" class="gradient-parent w-100 position-sticky" style="height: 0px; bottom: 0; left: 0; z-index: 2">
                            <div class="gradient-white w-100 position-absolute d-flex justify-content-center align-items-center size-13" style="bottom: -2px;" />
                        </div>
                    </template>
                    <template v-else>
                        <div v-show="gradient.mobile && images.length > 4" class="gradient-mobile position-absolute h-100" />    
                    </template>
                </transition>
            </div>
            <div v-if="desktopViewport" class="container-img-primary position-relative d-flex align-content-center">
                <image-product :source="img" :key="img" />
            </div>                
            <div v-else class="gallery-container" :class="{'mb-4': !desktopViewport}">
                <swiper :options="mobileSwiperConfig" ref="swiperGallery" @slideChange="setActive">
                    <swiper-slide v-for="{ picture_url } in images" :key="picture_url" class="d-flex align-content-center">
                        <image-product :source="picture_url" class="loaded img-mobile"/>
                    </swiper-slide>
                    <div class="swiper-pagination" slot="pagination" /> 
                </swiper>
            </div>
        </div>
    </main-modal>    

</template>

<script>
import MainModal from '../../../../common/components/modals/MainModal.vue'
import ImageProduct from '../Image.vue' // import IconArrow from '../../../../common/svg/iconArrow.vue'//  IconArrow
export default {
    components: { MainModal, ImageProduct,  },
    name: "LightBox",
    props: {
        active: {
            type: String
        },
        images: {
            type: Array
        }
    },
    data() {
        return {
            img: "", //this is the img that is currently focused
            gradient: { desktop: true, mobile: true },
            swiperConfig: {
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    dynamicBullets: true
                },
                breakpoints: {
                    0: {
                        slidesPerView: 'auto',
                        slidesPerColumn: 1,
                        spaceBetween: 20,
                    },
                    576: {
                        slidesPerView: 4,
                        slidesPerColumn: 1,
                        spaceBetween: 21,
                    },
                    992: {
                        slidesPerView: 5, 
                        slidesPerColumn: 1,
                        spaceBetween: 20,
                    },
                }
            },
            mobileSwiperConfig: {
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    dynamicBullets: true
                },
                breakpoints: {
                    0: {
                        slidesPerView: 1,
                        slidesPerColumn: 1,
                        spaceBetween: 40,
                    },
                }
            },
            desktopViewport: false,
            more: false,
        }
    },
    watch: {
        img(value) {
            if (this.desktopViewport) return;
            const swiper = this.$refs.swiperGallery.$swiper;
            this.images.forEach(({picture_url}, index) => {
                if (picture_url === value) swiper.slideTo(index);
            });
        },
        desktopViewport(val) {      // BEFORE MOVING THIS TO changeViewPort() (where it was), the v-resize directive has a bug
            this.changeDirection(val);// that keeps executing even after the component has been destroyed.
        }
    },
    methods: {
        setActive(){
            const index = this.$refs.swiperGallery.$swiper.activeIndex;
            this.img = this.images[index].picture_url;
            this.$refs.swiper.$swiper.slideTo(index);
        },
        changeDirection(value) {
            setImmediate(() => {
                this.$refs.swiper.$swiper.changeDirection(value ? 'vertical':'horizontal');
            })
        },
    },
    created() {
        this.img = this.active;
        this.desktopViewport = this.viewPort('lg');
    },
}
</script>
<style scoped>
    .modal-main-container::v-deep .modal-main {
        max-width: 760px;
        max-height: 90%;
        max-width: none;
    }
    .container-img-primary {
        border: 1px solid var(--gray-50);
        border-radius: 15px;
        overflow: hidden;
    }
    .img-product-details {
        width: 100px;
        height: 100px;
        border: 1px solid var(--gray-100);
        border-radius: 10px;
        overflow: hidden;
        opacity: 0.7;
        transition: var(--transition-1);
    }
    .active.img-product-details {
        border-bottom: 4px solid var(--main-red);
        opacity: 1;
    }
    .swiper-container {
        width: 100%;
    }
    .swiper-container::v-deep .swiper-slide {
        height: 100px !important;
        width: 100px !important;
    }

    .fade-enter-active, .fade-leave-active {
        transition: all .15s ease-in-out;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    .gradient-white {
        height: 50px;
        background: linear-gradient(to bottom, rgba(255,0,0,0), rgb(255, 255, 255));
    }
    .gradient-mobile {
        top: 0;
        right: 0; 
        z-index: 3;
        width: 30px;
        background: linear-gradient(to right, rgba(255,0,0,0), rgba(255, 255, 255, 0.855));
    }
    .gallery-controls svg {
        transition: var(--transition-1);
    }
    .gallery-controls svg:hover {
        fill: var(--main-red);
    }

    .img-mobile {
        border-radius: 10px;
        border: 1px solid var(--gray-50);
        overflow: hidden;
    }
    .swiper-pagination::v-deep .swiper-pagination-bullet {
        width: 18px;
        height: 18px;
    }
    .gallery-container > .swiper-container, .gallery-container .swiper-container::v-deep .swiper-slide, .img-mobile {
        min-width: 200px !important;
        min-height: 200px !important;

        width: 100% !important;
        height: 50vh !important;
    }
    @media (min-width: 576px) {
        .modal-main-container::v-deep .modal-main {
            width: 535px;
        }
        .gallery-container > .swiper-container, .gallery-container .swiper-container::v-deep .swiper-slide, .img-mobile {
            width: 464px !important;
            height: 464px !important;
        }
        .swiper-gallery {
            width: 465px;
        }
    }
    @media (min-width: 992px) {
        .swiper-container {
            height: 582px;
            width: unset;
        }
        .modal-main-container::v-deep .modal-main {
            width: 800px;
            height: 80%;
        }
        .container-img-primary {
            height: 630px;
            width: 630px;
        }
    }
    @media (min-width: 1200px) {
        .modal-main-container::v-deep .modal-main {
            width: 880px;
            height: 87%;
        }
        .container-img-primary {
            height: 700px;
            width: 700px;
        } 
    }
</style>
