<template>
    <div class="avatar-container d-flex align-items-center mb-2">
        <div v-if="!customerImg || error" class="avatar d-flex align-items-center justify-content-center mb-auto" :style="{background: getRandomColor}">
            <abbr>{{name.slice(0,1)+'.'+lastname.slice(0,1)}}</abbr>
        </div>
        <img v-else :src="customerImg" alt="profile" class="avatar mb-auto" @error="error = true">
        <div class="d-flex flex-column ms-2 ps-1 flex-grow-1">
            <div class="name-verified-wrapper d-flex align-items-center flex-wrap">
                <span class="subtitle-16 pe-md-2 pe-lg-1 me-2 text-limited" :class="customerVerified ? 'name-content-limited':'name-content'" style="text-transform: capitalize;">{{(name+' '+lastname) | textFormat}}</span>
                <div v-if="customerVerified" class="cursor-pointer" data-toggle="tooltip" data-placement="top" :title="$t('product.verified_buyer')">
                    <icon-check-circle :size="18" class="pb-1"/>
                    <span class="d-none d-md-inline d-lg-none d-xl-inline color-dark-gray-200 ms-2 weight-200">{{$t("product.verified_buyer")}}</span>
                </div>
                <span class="answer-options w-100">
                    <time class="answer-date color-dark-gray-300 weight-300 size-15 d-inline-flex">{{ $d(new Date(date), 'alt_short') | textFormat }}</time>
                    <icon-trash v-if="userAnswer" color="var(--gray-1001)" :size="21" class="ms-auto me-2" @click.native="$emit('deleteAnswer')"/>
                    <icon-edit v-if="userAnswer" color="var(--gray-1001)" :size="17" @click.native="$emit('editAnswer')" />
                    <icon-warning-circle v-if="!userAnswer" color="var(--gray-1001)" :size="17" class="ms-auto" @click.native="$emit('reportAnswer')"/>
                </span>
            </div>
            <star-rating v-if="showStar" :rating="rating" :starSize="22" :increment="0.1" :showRating="false" readOnly />
        </div>
        <time v-if="date" class="date color-dark-gray-300 text-end weight-300 ms-auto size-15" :class="{'mb-auto': showStar}" >{{ $d(new Date(date), 'alt_short') | textFormat }}</time>
    </div>
</template>

<script>
import IconTrash from '../../../../common/svg/iconTrash.vue';
import IconWarningCircle from '../../../../common/svg/iconWarningCircle.vue';
import iconCheckCircle from '../../../account/assets/svg/iconCheckCircle.vue'
import IconEdit from '../../../checkout/assets/svg/iconEdit.vue';
import StarRating from '../rating/StarRating.vue'
export default {
    components: { iconCheckCircle, IconEdit, StarRating,  IconTrash, IconWarningCircle },
    name: "Avatar",
    props: {
        name: {
            type: String
        },
        lastname: {
            type: String
        },
        rating: {
            type: Number
        },
        showStar: {
            type: Boolean,
            default: true
        },
        date: {
            type: [String, Date]
        },
        customerVerified: {
            type: Boolean
        },
        customerImg: {
            type: String
        },
        userAnswer: {
            type: Boolean
        }
    },
    computed:{
        getRandomColor() {
            const min = Math.ceil(1);
            const max = Math.floor(6);
            let generator = Math.floor(Math.random() * (max - min + 1)) + min;
            switch (generator) {
                case 1:
                    return '#FA9696';
                case 2:
                    return '#8FC6F5';
                case 3:
                    return '#61BEB8';
                case 4:
                    return '#FCD058';
                case 5:
                    return '#9CC574';
                case 6:
                    return '#AC74C5';                     
                default:
                    break;
            }
            return Math.floor(Math.random() * (max - min + 1)) + min;
        }
    },
    data() {
        return {
            error: false
        }
    },
}
</script>
<style scoped>
    .avatar {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        color: #fff;
        object-fit: cover;
    }
    .name-content-limited {
        max-width: 175px;
    }
    .name-content {
        max-width: 154px;
    }
    .answer-date, .answer-options {
        display: none;
    }    
    @media (min-width: 576px) {
        .name-content-limited {
            max-width: 210px; 
        }
        .name-content {
            max-width: 280px;
        }
    }
    @media (min-width: 768px) {
        .name-content-limited {
            max-width: 450px;
        }
        .name-content {
            max-width: 280px;
        }
    }
    @media (min-width: 992px) {
        .name-content-limited {
            max-width: 254px;
        }
        .name-content {
            max-width: 240px;
        }
    }
    @media (min-width: 1200px) {
        .name-content-limited {
            max-width: 390px;
        }
        .name-content {
            max-width: 280px;
        }
    }
    @media (min-width: 1400px) {
        .name-content-limited {
            max-width: 450px;
        }
    }

</style>
